<template>
  <div>
    <Table
      :columns="columns"
      :data="transformedOrderProducts"
      :disabled="disabled"
      :hover="false"
      custom-class="diff-table"
    >
      <template #cell-diffType="{ rowData: { type } }">
        <el-tooltip :key="type.key" effect="dark" :content="type.tooltipText" placement="top">
          <span>
            {{ type.text }}
          </span>
        </el-tooltip>
      </template>
      <template #cell-totalDiff="{ rowData: { amount } }">{{ formatMoney(amount) }}</template>
    </Table>
  </div>
</template>

<script>
import { currency } from '@/locale/numberConfig';
import { options } from '@/locale/dateConfig';
import { Table } from '@/modules/core';

const TABLE_HEADER = {
  SKU: 'sku',
  PRODUCT: 'name',
  QUANTITY: 'quantity',
  DIFF_TYPE: 'diffType',
  TOTAL_DIFF: 'totalDiff',
};

const generateUniqKey = () => Date.now() * Math.random();

export default {
  components: {
    Table,
  },
  props: { orderProducts: { type: Array, required: true }, disabled: { type: Boolean, default: false } },
  computed: {
    transformedOrderProducts() {
      return this.orderProducts.map((orderProduct) => ({
        ...orderProduct,
        type: this.formatDiffType(orderProduct.type),
      }));
    },
    columns() {
      return [
        { key: 'index', header: '', width: '60px' },
        {
          header: this.$t('commons.sku'),
          key: TABLE_HEADER.SKU,
          width: '120px',
        },
        {
          header: this.$t('commons.product'),
          key: TABLE_HEADER.PRODUCT,
        },
        {
          header: this.$t('orderDifferencesMatchModal.decisionTable.diffType'),
          key: TABLE_HEADER.DIFF_TYPE,
          width: '85px',
        },
        {
          header: this.$t('orderDifferencesMatchModal.decisionTable.quantity'),
          key: TABLE_HEADER.QUANTITY,
          width: '95px',
        },

        {
          header: this.$t('orderDifferencesMatchModal.decisionTable.totalDiff'),
          key: TABLE_HEADER.TOTAL_DIFF,
          width: '120px',
        },
      ];
    },
  },
  methods: {
    formatMoney(value) {
      return typeof value === 'number' && !Number.isNaN(value)
        ? Number(value).toLocaleString(this.$i18n.locale, currency)
        : '-';
    },
    formatDate(ms) {
      return ms ? new Date(ms).toLocaleDateString(this.$i18n.locale, options.short) : null;
    },
    formatDiffType(type) {
      return {
        key: generateUniqKey(),
        tooltipText: this.$t(`creditMatchModal.table.common.diffType.${type}.text`),
        text: this.$t(`creditMatchModal.table.common.diffType.${type}.label`),
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
::v-deep .diff-table {
  th,
  td {
    background: #f6f7f9;
  }
}
</style>
