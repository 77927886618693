<template>
  <div>
    <el-radio-group v-model="decision" class="d-flex flex-column gap-1 mb-2">
      <el-radio :label="DECISIONS.CREATE" class="m-0"
        >{{ $t('orderDifferencesMatchModal.decisionTable.newOrderEvent') }}
      </el-radio>
      <el-radio :label="DECISIONS.LINK" class="m-0" :disabled="!transformedOrders.length">{{
        $t('orderDifferencesMatchModal.decisionTable.linkToExisting')
      }}</el-radio>
    </el-radio-group>
    <Table
      :columns="columns"
      :data="transformedOrders"
      :row-selection.sync="rowSelection"
      row-click-expand-toggle
      :select-all-toggle="false"
      :expandable.sync="expandableRows"
      :disabled="decision === DECISIONS.CREATE"
      border
      rounded
      hover
    >
      <template #cell-date="{ rowData: { date } }">
        {{ formatDate(date) }}
      </template>
      <template #cell-reference="{ rowData: { eventReferences } }">
        <Button
          type="link"
          class="p-0 m-0"
          :disabled="decision === DECISIONS.CREATE"
          @click.stop="$emit('document-open', eventReferences[0].documentId)"
        >
          {{ eventReferences[0].reference }}
        </Button>
      </template>
      <template #cell-totalDiff="{ rowData: { totalDifference } }">
        {{ totalDifference ? formatMoney(totalDifference) : '-' }}
      </template>
      <template #expandable-content="{ rowData: { orderProducts } }">
        <DifferenceInfo :order-products="orderProducts" :disabled="decision === DECISIONS.CREATE" />
      </template>
    </Table>
  </div>
</template>

<script>
import { options } from '@/locale/dateConfig';
import { Table, Button } from '@/modules/core';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import DifferenceInfo from './DifferenceInfo';
import { DECISIONS } from '../constants';

const TABLE_HEADER = {
  DATE: 'date',
  REFERENCE: 'reference',
  TOTAL_DIFF: 'totalDiff',
};

export default {
  components: { Table, Button, DifferenceInfo },
  props: {
    orders: { type: Array, default: () => [] },
    currentDecision: { type: String, default: null },
    eventToValidateDate: { type: String, required: true },
  },
  setup() {
    const { formatToCurrency } = useCurrency();

    return { formatToCurrency };
  },
  data() {
    return {
      DECISIONS,
      rowSelection: [],
      expandableRows: {},
    };
  },
  computed: {
    transformedOrders() {
      return this.orders.map((order) => ({
        ...order,
        expandable: order.orderProducts.length,
        expandableCustomClass: 'p-0',
        selectionDisabled:
          this.decision === DECISIONS.CREATE ||
          !order.orderProducts.length ||
          new Date(order.date) > new Date(this.eventToValidateDate),
      }));
    },
    selectedData() {
      return Object.values(this.rowSelection).map((rowIndex) => this.orders[rowIndex]);
    },
    columns() {
      return [
        {
          header: this.$t('commons.date'),
          key: TABLE_HEADER.DATE,
          width: '120px',
        },
        {
          header: this.$t('commons.reference'),
          key: TABLE_HEADER.REFERENCE,
        },
        {
          header: this.$t('orderDifferencesMatchModal.decisionTable.totalDiff'),
          key: TABLE_HEADER.TOTAL_DIFF,
          width: '120px',
        },
      ];
    },
    decision: {
      set(decision) {
        this.$emit('update:current-decision', decision);
      },
      get() {
        return this.currentDecision;
      },
    },
  },
  watch: {
    decision(data) {
      if (data === DECISIONS.CREATE) {
        this.rowSelection = [];
      }
    },
    selectedData(data) {
      this.$emit('selected-data', data);
    },
    rowSelection(data) {
      if (data.length) this.decision = DECISIONS.LINK;
    },
  },
  methods: {
    formatDate(ms) {
      return ms ? new Date(ms).toLocaleDateString(this.$i18n.locale, options.short) : null;
    },
    formatMoney(value) {
      return this.formatToCurrency(value) ?? '-';
    },
  },
};
</script>
<style scoped lang="scss">
@import '@/stylesheets/scss/global';

::v-deep .el-radio__input + .el-radio__label,
.el-radio__input.is-checked + .el-radio__label {
  line-height: 1.2;
  color: $typography-primary;
  font-weight: $font-weight-normal;
}
</style>
