<template>
  <el-dialog
    visible
    top="2%"
    :show-close="false"
    append-to-body
    custom-class="order-diffs-match-modal"
    @close="$emit('close')"
  >
    <template #title>
      <div class="header d-flex justify-content-between">
        <div>
          <h2 class="title">{{ $t('orderDifferencesMatchModal.title') }}</h2>
          <p>{{ supplier.name }}</p>
        </div>
        <Button type="icon" class="p-0" @click="$emit('close')">
          <CloseIcon />
        </Button>
      </div>
    </template>
    <div class="modal-content-wrapper">
      <div class="tables">
        <div class="table-wrapper">
          <h3 class="table-title fw-bold">
            {{ $t('orderDifferencesMatchModal.supplierOrderTable.title', { supplier: supplier.name }) }}
          </h3>
          <SupplierOrderTable :order="order" @document-open="previewDocumentId = $event" />
        </div>
        <div class="divider" />
        <div class="table-wrapper">
          <div class="d-flex justify-content-between">
            <h3 class="table-title fw-bold">
              {{ $t('orderDifferencesMatchModal.decisionTable.title', { customer: business.name }) }}
            </h3>
            <PeriodNavigator
              :start="ordersRange.start"
              :end="ordersRange.end"
              @forward="goForwardOrders()"
              @backward="goBackwardOrders()"
            />
          </div>
          <DecisionTable
            v-loading="orderLoading"
            :orders="ordersWithDifferences"
            :current-decision.sync="decision"
            :event-to-validate-date="order.date"
            @document-open="previewDocumentId = $event"
            @selected-data="selectedData = $event"
          />
        </div>
      </div>
    </div>
    <div slot="footer">
      <Button type="secondary" @click="$emit('close')">{{ $t('commons.cancel') }}</Button>
      <Button :disabled="!decision || (decision === DECISIONS.LINK && !selectedData.length)" @click="handleSave">{{
        $t('commons.save')
      }}</Button>
    </div>
    <DocumentModal
      v-if="previewDocumentId"
      visible
      :document-id="previewDocumentId"
      @close="previewDocumentId = null"
    />
  </el-dialog>
</template>

<script>
import { ref, computed } from 'vue';
import { reject, isNil, omit } from 'ramda';

import { Button, PeriodNavigator } from '@/modules/core';
import { CloseIcon } from '@/assets/icons';
import { DocumentModal } from '@/modules/documentModal';
import { useOrderCreate, useOrderPatch } from '@/modules/order';
import { useDeliveryCreate, useDeliveryPatch, useDeliveries } from '@/modules/delivery';

import { getISOMonth, createDifferenceRow } from './utils';
import { useOrders } from './composition';
import { DecisionTable, SupplierOrderTable } from './components';
import { DECISIONS } from './constants';

export default {
  components: { Button, DecisionTable, SupplierOrderTable, PeriodNavigator, DocumentModal, CloseIcon },
  props: {
    supplier: { type: Object, required: true },
    business: { type: Object, required: true },
    order: { type: Object, required: true },
    defaultMonth: {
      type: [String, Number],
      validator: (value) => (typeof value === 'string' ? value.match(/^\d{4}-\d{2}$/) : Number.isInteger(value)),
      default: () => getISOMonth(new Date()),
    },
  },
  setup(props) {
    const supplierId = computed(() => props.supplier.id);
    const businessId = computed(() => props.business.id);
    const {
      orders,
      loading: orderLoading,
      range: ordersRange,
      goForward: goForwardOrders,
      goBackward: goBackwardOrders,
    } = useOrders(supplierId, businessId, props.defaultMonth);

    const orderIds = computed(() => orders.value.map(({ id }) => id));
    const { deliveries } = useDeliveries(
      computed(() => ({
        businessId: businessId.value,
        orderIds: [...orderIds.value, props.order.id],
      }))
    );

    const { createOrder, loading: createOrderLoading } = useOrderCreate();
    const { createDelivery, loading: createDeliveryLoading } = useDeliveryCreate();
    const { deliveryPatch, loading: deliveryPatchLoading } = useDeliveryPatch();
    const { orderPatch, loading: orderPatchLoading } = useOrderPatch();

    return {
      orders: computed(() =>
        reject(isNil)(
          orders.value.map((order) => {
            if (!order.eventReferences.length) return;

            return {
              ...order,
              delivery: deliveries.value.find(
                (delivery) => order.eventReferences[0].documentId === delivery.eventReferences[0]?.documentId
              ),
            };
          })
        )
      ),
      orderLoading,
      createOrder,
      createDelivery,
      deliveryPatch,
      orderPatch,
      ordersRange,
      goForwardOrders,
      goBackwardOrders,
      deliveries,
      previewDocumentId: ref(null),
      firstLoad: ref(true),
      decision: ref(null),
      DECISIONS,
      selectedData: ref([]),
      actionLoading: computed(
        () =>
          createOrderLoading.value ||
          createDeliveryLoading.value ||
          deliveryPatchLoading.value ||
          orderPatchLoading.value
      ),
    };
  },
  computed: {
    ordersWithDifferences() {
      return this.orders.map((order) => createDifferenceRow(order)).sort((a, b) => a.date - b.date);
    },
  },
  watch: {
    orders() {
      this.decision = null;
    },
  },
  methods: {
    async handleSave() {
      if (this.actionLoading) return;
      try {
        if (this.decision === DECISIONS.CREATE) {
          const { customerId, supplierId, date, eventReferences, source, rounding, products, delivery } = this.order;
          const productsWithItems = products
            .filter(({ associatedTo }) => !associatedTo)
            .map(({ productId, quantity, price, discount, id, type }) =>
              reject(isNil)({
                productId,
                quantity,
                price,
                discount,
                id,
                type,
              })
            );

          const associatedProducts = products.filter(({ associatedTo }) => associatedTo);

          for (const associatedProduct of associatedProducts) {
            const parent = productsWithItems.find(({ id }) => id === associatedProduct.associatedTo);
            if (parent) {
              if (parent.items) {
                parent.items.push(
                  reject(isNil)({
                    productId: associatedProduct.productId,
                    quantity: associatedProduct.quantity,
                    price: associatedProduct.price,
                    discount: associatedProduct.discount,
                    type: associatedProduct.type,
                  })
                );
              } else {
                parent.items = [
                  reject(isNil)({
                    productId: associatedProduct.productId,
                    quantity: associatedProduct.quantity,
                    price: associatedProduct.price,
                    discount: associatedProduct.discount,
                    type: associatedProduct.type,
                  }),
                ];
              }
            }
          }

          const orderCreateParams = reject(isNil)({
            businessId: customerId,
            customerId,
            supplierId,
            date,
            type: 'documentation',
            source: omit(['__typename'], source),
            eventReferences: eventReferences.map((ref) => omit(['__typename', 'document'], ref)),
            rounding,
            products: productsWithItems.map((product) => omit(['id'], product)),
          });

          const deliveryCreateParams = reject(isNil)({
            businessId: customerId,
            customerId,
            supplierId,
            date,
            products: delivery.products.map(({ productId, quantity, items }) =>
              reject(isNil)({
                productId,
                quantity,
                items: items?.map((item) => omit(['__typename'], item)),
              })
            ),
            eventReferences: eventReferences.map((ref) => omit(['__typename', 'document'], ref)),
            documentId: eventReferences[0].documentId,
          });
          await Promise.all([
            this.createOrder({ createParams: orderCreateParams }),
            this.createDelivery({ createParams: deliveryCreateParams }),
          ]);
        } else if (this.decision === DECISIONS.LINK) {
          await Promise.all(
            this.selectedData.map((order) => {
              const eventReferences = [...order.eventReferences, this.order.eventReferences[0]].map((ref) =>
                omit(['document', '__typename'], ref)
              );
              return Promise.all([
                this.orderPatch({ id: order.id, data: { eventReferences } }),
                this.deliveryPatch({ id: order.delivery.id, data: { eventReferences } }),
              ]);
            })
          );
        }
        this.$message.success(this.$i18n.t('commons.messages.action.success'));
        this.$emit('refetch');
        this.$emit('close');
      } catch (e) {
        console.error(e);
        this.$message.error(this.$i18n.t('commons.messages.action.error'));
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.header {
  .title {
    font-weight: 700;
    font-size: 1.7em;
  }
}

.modal-content-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .tables {
    flex: 1;
    display: grid;
    grid-template-columns: 1fr 1px 1fr;
    margin-bottom: 20px;
    background: $white;
    padding: 0px 30px 20px;
    gap: 15px;

    .divider {
      height: 100%;
      width: 1px;
      background: $outline;
    }

    .table-wrapper {
      display: grid;
      grid-template-rows: 24px 1fr;
      gap: 10px;

      .table-title {
        color: $typography-primary;
        font-size: 1.15em;
      }
    }
  }
}

::v-deep .order-diffs-match-modal {
  $modal-width: 95vw;
  color: $typography-primary;
  height: 93vh;
  width: $modal-width;
  border-radius: 6px;
  margin-bottom: 0;
  display: grid;
  grid-template-rows: 100px 1fr 65px;

  .el-dialog__header {
    background: $white;
    border-radius: 6px 6px 0px 0px;
  }

  .el-dialog__body {
    padding: 0px;
    width: $modal-width;
  }
}
</style>
